export enum STORE_KEY {
  STORE_USE_ROARING_KEY = "store.roaring.query",
}

export interface LegalEntityStoreObject {
  [key: string]: boolean | undefined;
}

interface StoredData {
  [STORE_KEY.STORE_USE_ROARING_KEY]?: boolean;
}

const NAME = "wlx-nordic-assisted-onboarding";

const defaultValues = {
  [STORE_KEY.STORE_USE_ROARING_KEY]: undefined,
};

let storedData: StoredData = { ...defaultValues };
const storedStringData = window.localStorage.getItem(NAME);
if (storedStringData) {
  try {
    storedData = { ...defaultValues, ...JSON.parse(storedStringData) };
  } catch (err) {}
}

export const Store = {
  getValue<T extends keyof StoredData>(key: T): StoredData[T] {
    return storedData[key];
  },

  setValue<Key extends keyof StoredData>(key: Key, value: StoredData[Key]) {
    storedData[key] = value;
    try {
      window.localStorage.setItem(NAME, JSON.stringify(storedData));
    } catch (err) {
      //Do not log
    }
  },

  getStorage() {
    return storedData;
  },
};
