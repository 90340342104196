import React, { ReactNode, useEffect, useRef, useState } from "react";
import cx from "classnames";
import styles from "./BankStatement.module.scss";
import {
  FileData,
  FileUpload,
  getUploadBaseUrl,
} from "../../../../components/uploads/FileUpload";
import { View } from "../Bank";
import { Form, FormContainer } from "../../../../components/form/Form";
import { HiddenInput } from "../../../../components/form/HiddenInput";
import { RequiredValidator } from "../../../../components/form/validators/RequiredValidator";
import { StoryContinueButton } from "../../../../components/story/StoryContinueButton";
import { T } from "../../../../components/translation/T";
import { Card } from "../../../../components/cards/Card";
import { Beacon } from "../../../../components/beacon/Beacon";
import { Validity } from "../../../../data/types";
import { Access } from "../../../../data/proxy";
import { useAccess } from "../../../../hooks/useAccess";
import { Trans, useTranslation } from "react-i18next";
import { WarningBox } from "../../../../components/boxes/WarningBox";
import {
  BankAccountSource,
  ConfirmedStatus,
  dataBank,
} from "../../../../data/dataBank";
import { useSuspendedQuery } from "../../../../hooks/useSuspendedQuery";
import { TextInput } from "../../../../components/form/TextInput";
import { IBANValidator } from "../../../../components/form/validators/IBANValidator";
import { useQueryClient } from "@tanstack/react-query";
import { NumberedItem } from "../../../../components/numberedItem/NumberedItem";
import { BICValidator } from "../../../../components/form/validators/BICValidator";
import { Dynamic } from "../../../../components/animate/Dynamic";
import { useLinkId } from "../../../../hooks/useLinkId";

interface Props {
  onViewChange: (view: View) => void;
  next: () => void;
  disabled?: boolean;
  externalAccount: ConfirmedStatus;
  buttonName?: ReactNode;
  hideButton?: boolean;
}

export const BankStatement: React.FunctionComponent<Props> = ({
  next,
  disabled = false,
  buttonName = <T>Continue</T>,
  externalAccount,
  hideButton = false,
}) => {
  const linkId = useLinkId();
  const access = useAccess();
  const { t } = useTranslation();
  const { data: bank } = useSuspendedQuery(
    dataBank(access).fetchConfirmedStatus(linkId)
  );

  const ref = useRef<HTMLFormElement>(null);
  const [file, setFile] = useState<FileData | undefined>(
    bank.done && bank.source === BankAccountSource.BANK_STATEMENT
      ? {
          name: t("Uploaded bank statement"),
          type: "",
        }
      : undefined
  );
  const [iban, setIban] = useState<string>(bank.iban || bank.bankAccount || "");
  const [bic, setBic] = useState<string>(bank.bic || "");
  const [disableInput, setDisableInput] = useState<boolean>(false);
  const [allowUpload, setAllowUpload] = useState<boolean>(!!iban && !!bic);

  const formContainer = useRef<FormContainer>();
  const timer = useRef<number>();

  const queryClient = useQueryClient();

  useEffect(() => {
    const onUpdate = () => {
      clearTimeout(timer.current);
      const valid = !!formContainer.current?.isValid;
      timer.current = window.setTimeout(() => {
        setAllowUpload(valid);
      }, 200);
    };

    const container = formContainer.current;
    container?.addListener(onUpdate);
    return () => {
      container?.removeListener(onUpdate);
    };
  }, []);

  return (
    <div>
      <div className={styles.wrapper}>
        <Card>
          <div className={styles.box}>
            <p>
              <Trans t={t}>
                Please enter <b>IBAN</b>, <b>BIC/SWIFT</b> and upload a bank
                statement confirming the bank account connected to your company.
              </Trans>
            </p>
          </div>
          <Dynamic name={allowUpload ? "one" : "two"}>
            {allowUpload || file ? null : (
              <div style={{ padding: "10px 0 20px 0" }}>
                <WarningBox relative>
                  <Trans t={t}>
                    You must add <b>IBAN</b> and <b>BIC</b> before uploading
                    statement.
                  </Trans>
                </WarningBox>
              </div>
            )}
          </Dynamic>

          <Form formContainer={formContainer} name="klarna-accounts" ref={ref}>
            <div className="m-top-40">
              <TextInput
                name="iban"
                value={iban}
                onChange={(value) => setIban(value)}
                label="IBAN"
                validators={[
                  new RequiredValidator("IBAN is required"),
                  new IBANValidator("IBAN is incorrectly formatted"),
                ]}
                hint='E.g. "SE45 5000 0000 0583 9825 7466"'
                disabled={disableInput}
              />
            </div>
            <div className="m-top-20">
              <TextInput
                name="bic"
                value={bic}
                onChange={(value) => setBic(value)}
                label="BIC/SWIFT"
                validators={[
                  new RequiredValidator("BIC/SWIFT is required"),
                  new BICValidator("BIC/SWIFT is incorrectly formatted"),
                ]}
                hint='E.g. "AAAABBCC123"'
                disabled={disableInput}
              />
            </div>
          </Form>
          <div>
            <div className={cx(styles.upload, "m-top-30")}>
              <div className={styles.header}>
                <b>
                  <T>Bank statement</T>
                </b>
                {!!file && (
                  <Beacon className="mini" validity={Validity.VALID} />
                )}
              </div>

              <div className={styles.info}>
                <p>
                  <T>Make sure that the attached bank statement includes </T>
                </p>
                <NumberedItem
                  className={styles.numbered}
                  index={1}
                  color="#e5f2f4"
                >
                  <Trans t={t}>
                    the name of the <b>bank account holder</b>,
                  </Trans>
                </NumberedItem>
                <NumberedItem
                  className={styles.numbered}
                  index={2}
                  color="#e5f2f4"
                >
                  <Trans t={t}>
                    the <b>bank account number</b>,
                  </Trans>
                </NumberedItem>
                <NumberedItem
                  className={styles.numbered}
                  index={3}
                  color="#e5f2f4"
                >
                  <Trans t={t}>
                    the <b>name of the bank</b> (can also be logo, URL, or BIC),
                  </Trans>
                </NumberedItem>
                <NumberedItem
                  className={styles.numbered}
                  index={4}
                  color="#e5f2f4"
                >
                  <Trans t={t}>
                    and <b>date</b> showing when the document was created (it
                    must not be older than two months)
                  </Trans>
                </NumberedItem>
              </div>
              <FileUpload
                endpoint={`${getUploadBaseUrl()}/api/merchant/bank/${linkId}/statement`}
                onUpload={(file) => {
                  setDisableInput(true);
                  setFile(file);
                }}
                onRemove={() => {
                  queryClient.invalidateQueries(
                    dataBank(access).getConfirmedStatusKey(linkId)
                  );
                  setDisableInput(false);
                  setFile(undefined);
                }}
                buttonText="Upload Bank statement"
                disabled={!allowUpload || disabled}
                appends={{
                  iban,
                  bic,
                }}
              />
            </div>
          </div>
        </Card>
      </div>

      <Form
        name="third-party-upload"
        onSubmit={(_, form) => {
          if (!form.isValid) {
            return;
          }

          next();
        }}
      >
        <HiddenInput
          label="Missing file"
          value={!!file ? true : undefined}
          className={styles.error}
          validators={[
            new RequiredValidator("You must upload a bank statement"),
          ]}
          scrollToRef={ref}
        />

        <Dynamic name={hideButton ? "hide" : "show"}>
          {hideButton ? null : (
            <StoryContinueButton
              type="submit"
              disabled={access === Access.VIEW || disabled}
              ghost={externalAccount.done}
            >
              {buttonName}
            </StoryContinueButton>
          )}
        </Dynamic>
      </Form>
    </div>
  );
};
