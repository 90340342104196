import cx from "classnames";
import styles from "./SelectableCard.module.scss";
import { ReactNode } from "react";
import { Card } from "./Card";
import { MdCheck } from "react-icons/md";

export enum SelectableType {
  RADIO,
  CHECKBOX,
}

interface Props<T> {
  children: ReactNode;
  data: T;
  active: boolean;
  onClick: (data: T) => void;
  className?: string;
  type?: SelectableType;
  compact?: boolean;
}

export function SelectableCard<T>({
  className,
  data,
  active,
  onClick,
  children,
  compact,
  type = SelectableType.RADIO,
}: Props<T>) {
  return (
    <div className="relative">
      <Card
        className={cx(styles.selectCard, className, {
          [styles.compact]: compact,
        })}
        bodyStyle={{
          transition: "all 150ms ease-in-out",
          border: active ? "2px solid #007b7c" : "2px solid transparent",
        }}
      >
        <div className={styles.overlay} onClick={() => onClick(data)} />
        <div className={styles.selectCardBody}>
          <div
            className={cx(styles.tickbox, {
              [styles.checkbox]: type === SelectableType.CHECKBOX,
              [styles.radio]: type === SelectableType.RADIO,
              [styles.selected]: active,
            })}
          >
            <MdCheck
              className={cx(styles.checkmark, {
                [styles.show]: type === SelectableType.CHECKBOX,
                [styles.selected]: active,
              })}
            />
          </div>
          <div className="flex-columns gap-10 flex-1">{children}</div>
        </div>
      </Card>
    </div>
  );
}
