import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  AssociateId,
  CompanyRegistrationId,
  ContractVersion,
  Country,
  Language,
  LegalEntityType,
  LinkId,
  ProductType,
} from "../../data/models/ContractTypes";
import { contractState } from "../../state/contractState";
import i18n from "../../i18n";
import { ViewerStatus } from "../../data/dataMerchant";
import cx from "classnames";
import {
  BankItem,
  BankType,
  ConfirmedStatus,
  dataBank,
  RoaringAccount,
  WhitelistedEndpoint,
} from "../../data/dataBank";
import { TextInput } from "../../components/form/TextInput";
import { Access } from "../../data/proxy";
import { Select } from "../../components/form/Select";
import { getCountryOpts } from "../../components/utils";
import { useTranslation } from "react-i18next";
import { RequiredValidator } from "../../components/form/validators/RequiredValidator";
import { Button } from "../../components/interactions/Buttons/Button";
import { useQueryClient } from "@tanstack/react-query";
import { Status } from "../../data/types";
import { Dynamic } from "../../components/animate/Dynamic";
import { Spinner } from "../../components/spinner/Spinner";
import { GenericError } from "../../components/Errors/GenericError";
import { T } from "../../components/translation/T";
import styles from "./KlarnaTestPage.module.scss";
import { useSearchParams } from "react-router-dom";
import { useSuspendedQueries } from "../../hooks/useSuspendedQueries";
import { useLinkId } from "../../hooks/useLinkId";

const SEARCH_CODE = "code";
const SEARCH_ERROR = "error";

export const KLARNA_TEST_PAGE_URL = "/:linkId/roaring-test";

interface Props {
  ready: boolean;
  confirmedStatus: ConfirmedStatus;
  cachedAccounts: RoaringAccount[];
}

export const KlarnaTestPage: React.FunctionComponent = () => {
  const setContractState = useSetRecoilState(contractState);
  const [ready, setReady] = useState<boolean>(false);
  const linkId = useLinkId();

  const [{ data: confirmedStatus }, { data: cachedAccounts }] =
    useSuspendedQueries<any>({
      queries: [
        dataBank(Access.VIEW_AND_EDIT).fetchConfirmedStatus(linkId),
        dataBank(Access.VIEW_AND_EDIT).fetchAccounts(linkId),
      ],
    });

  console.log("confirmStatus", confirmedStatus);
  console.log("cachedAccounts", cachedAccounts);

  useEffect(() => {
    let lang = Language.ENGLISH as string;
    try {
      const urlSearchParams = new URLSearchParams(window.location.search);
      ({ lang } = Object.fromEntries(urlSearchParams.entries()));
    } catch (err) {}

    i18n.changeLanguage(lang);

    // TODO: update with Swedish data
    setContractState({
      productType: ProductType.BAMBORA_ONE,
      contractData: {
        mccIndustry: "Consulting, management and public relations services",
        version: 7 as ContractVersion,
        country: "SE" as Country,
        organizationNumber: "1231119747" as CompanyRegistrationId,
        companyName: "CONSULTING JERZY CIECIERSKI",
        legalEntityType: LegalEntityType.LIMITED,
        invoiceAddress: {
          street: "ul. Piwna 12/14",
          postalCode: "00-263",
          city: "Warszawa",
          countryCode: Country.SWEDEN,
        },
        address: {
          street: "ul. Piwna 12/14",
          postalCode: "00-263",
          city: "Warszawa",
          countryCode: Country.SWEDEN,
        },
      },
      enabledFeatures: [],
      confirmedContract: false,
      contractViewer: {
        associateId: "" as AssociateId,
        language: Language.ENGLISH,
        status: ViewerStatus.INFORMATION,
      },
    });
    setReady(true);
  }, [setContractState]);

  return (
    <section className={styles.page}>
      <article>
        <Inner
          ready={ready}
          cachedAccounts={cachedAccounts}
          confirmedStatus={confirmedStatus}
        />
      </article>
    </section>
  );
};

const Inner: React.FunctionComponent<Props> = ({
  ready,
  cachedAccounts,
  confirmedStatus,
}) => {
  const contract = useRecoilValue(contractState);
  const { t, i18n } = useTranslation();
  const cachedLinkId = useLinkId();
  const [linkId, setLinkId] = useState<string>(cachedLinkId);
  const [countryCode, setCountryCode] = useState<Country>(Country.DENMARK);
  const [ownerType, setOwnerType] = useState<BankType>(BankType.BUSINESS);
  const [status, setStatus] = useState<Status>(Status.DEFAULT);
  const [banks, setBanks] = useState<BankItem[]>([]);
  const [search, setSearch] = useState<string>("");
  const queryClient = useQueryClient();
  const datalistIdentifier = useRef<string>(window.crypto.randomUUID());
  const [params] = useSearchParams();
  const error = params.get(SEARCH_ERROR);
  const code = params.get(SEARCH_CODE);
  const [token, setToken] = useState<string>();

  useEffect(() => {
    if (!code) {
      return;
    }

    setToken(code);
  }, [code]);

  console.log("error", error);
  console.log("code", code);

  const signIn = useCallback(() => {
    const currentUrl = window.location.href;
    const returnUrl = currentUrl.split("?")[0];
    dataBank(Access.VIEW_AND_EDIT)
      .initRoaring(linkId as LinkId, search, returnUrl, ownerType, countryCode)
      .then(({ url }) => {
        window.location.href = url;
      })
      .catch((err) => {
        console.log("err", err);
      });
  }, [linkId, countryCode, search, ownerType]);

  const fetchBanks = useCallback(async () => {
    try {
      setStatus(Status.PENDING);
      const data = await queryClient.fetchQuery(
        dataBank(Access.VIEW_AND_EDIT)[WhitelistedEndpoint.FETCH_BANKS](
          linkId as LinkId,
          countryCode,
          ownerType
        )
      );

      console.log("data", data);
      setStatus(Status.SUCCESS);
      setBanks(data);
    } catch (err) {
      console.log("err", err);
      setStatus(Status.ERROR);
    }
  }, [linkId, countryCode, ownerType, queryClient]);

  const countries = useMemo(
    () => getCountryOpts(t, i18n.language, false, null),
    [i18n.language, t]
  );

  const ownerTypes = useMemo(() => {
    return Object.values(BankType).map((item) => ({
      value: item,
      text: t(item),
    }));
  }, [t]);

  if (!ready) {
    return null;
  }

  const enableLogin = !!banks.find((item) => item.name === search);

  if (token) {
    return <Code code={token} reset={() => setToken("")} />;
  }

  return (
    <div>
      <div className={cx("tablet-columns", styles.columns)}>
        <div>
          <Select
            onChange={(value) => {
              setCountryCode(value as Country);
              setBanks([]);
            }}
            name="countryCode"
            label="Country"
            alternatives={countries}
            value={countryCode}
            validators={[new RequiredValidator("You must select country")]}
          />
        </div>

        <div>
          <Select
            onChange={(value) => {
              setOwnerType(value as BankType);
              setBanks([]);
            }}
            name="ownerType"
            label="Owner of account"
            alternatives={ownerTypes}
            value={ownerType}
            validators={[
              new RequiredValidator("You must select an owner of the account"),
            ]}
          />
        </div>

        <div>
          <TextInput
            className="compact"
            onChange={(value) => {
              setLinkId(value);
              setBanks([]);
            }}
            name="linkId"
            label="Enter link id"
            value={linkId}
          />
        </div>

        <div>
          <Button block onClick={fetchBanks}>
            Fetch banks
          </Button>
        </div>
      </div>

      <Dynamic name={status}>
        {Status.PENDING === status ? (
          <div>
            <Spinner />
          </div>
        ) : null}

        {Status.ERROR === status ? (
          <div>
            <GenericError />
            <Button
              block
              onClick={() => {
                setStatus(Status.PENDING);
                setTimeout(() => {
                  fetchBanks();
                }, 300);
              }}
            >
              <T>Retry</T>
            </Button>
          </div>
        ) : null}

        {Status.SUCCESS === status ? (
          <div>
            <div className={cx("tablet-columns", styles.banks, styles.columns)}>
              <div>
                <TextInput
                  className="compact"
                  list={datalistIdentifier.current}
                  onChange={(value) => {
                    setSearch(value);
                  }}
                  value={search}
                  label={<T>Search bank</T>}
                />
              </div>
              <div>
                <Button block onClick={signIn} disabled={!enableLogin}>
                  {enableLogin ? (
                    <>
                      <T>Sign in to</T> {contract.contractData.companyName}
                    </>
                  ) : (
                    <T>Select bank</T>
                  )}
                </Button>
              </div>
            </div>

            <datalist id={datalistIdentifier.current}>
              {banks.map((opt) => {
                const key = opt.name;
                return (
                  <option value={key} key={key}>
                    {key}
                  </option>
                );
              })}
            </datalist>

            <ul>
              {banks.map((item) => {
                return <li key={item.name}>{item.name}</li>;
              })}
            </ul>
          </div>
        ) : null}
      </Dynamic>
    </div>
  );
};

const Code: React.FunctionComponent<{ code: string; reset: () => void }> = ({
  code,
  reset,
}) => {
  // const linkId = useLinkId();
  // const [selected, setSelected] = useState<string>();

  // const [{ data: accounts }] = useSuspendedQueries({
  //   queries: [dataBank(Access.VIEW_AND_EDIT).fetchAccounts(linkId, code)],
  // });

  return null;

  // useEffect(() => {
  //   if (accounts.length === 1) {
  //     setSelected(accounts[0].iban);
  //   }
  // }, [accounts]);

  // console.log("selected", selected);

  // return (
  //   <div>
  //     <ul>
  //       {accounts.map((account) => {
  //         return (
  //           <li
  //             key={account.iban}
  //             className={cx(styles.listItem, {
  //               [styles.selected]: account.iban === selected,
  //             })}
  //           >
  //             <button
  //               className={cx(styles.button)}
  //               onClick={() => setSelected(account.iban)}
  //             >
  //               <dl>
  //                 <dt>name:</dt>
  //                 <dd>{account.accountName}</dd>
  //                 <dt>iban:</dt>
  //                 <dd>{account.iban}</dd>
  //                 <dt>owner:</dt>
  //                 <dd>{account.accountOwner}</dd>
  //               </dl>
  //             </button>
  //           </li>
  //         );
  //       })}
  //       <li />
  //     </ul>
  //   </div>
  // );
};
