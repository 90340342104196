import React, { useCallback, useRef, useState } from "react";
import styles from "./PayoutAccountCollection.module.scss";
import { useRecoilValue } from "recoil";
import { dataAssociates, isPrimary } from "../../data/dataAssociates";
import { useLinkId } from "../../hooks/useLinkId";
import { contractState } from "../../state/contractState";
import { T } from "../../components/translation/T";
import { useViewerAsAssociate } from "../../hooks/useViewerAsAssociate";
import { Trans, useTranslation } from "react-i18next";
import { Logo } from "../../components/images/Logo";
import { Country } from "../../data/models/ContractTypes";
import { Status } from "../../data/types";
import { useCountry } from "../../hooks/useCountry";
import { useSuspendedQueries } from "../../hooks/useSuspendedQueries";
import { Associate } from "../../data/dataMerchant";
import { TI } from "../../i18n";
import { SuccessBox } from "../../components/boxes/SuccessBox";
import { NumberedItem } from "../../components/numberedItem/NumberedItem";
import { StoryStepProps } from "../../components/story/Story";
import { StoryPage } from "../../components/story/StoryPage";
import { LanguageSwitch } from "../../components/languageSwitch/LanguageSwitch";
import { Access } from "../../data/proxy";
import { Dynamic } from "../../components/animate/Dynamic";
import { BankgiroInner } from "../DataCollection/Bank/bankgiro/Bankgiro";
import { BankStatement } from "../DataCollection/Bank/bankStatement/BankStatement";
import { Roaring } from "./Roaring/Roaring";
import { routeState } from "../../state/routeState";

export const PayoutAccountCollection: React.FunctionComponent<
  Partial<StoryStepProps>
> = (props) => {
  return <PayoutAccountCollectionKlarna {...props} />;
};

export const PayoutAccountCollectionKlarna: React.FunctionComponent<
  Partial<StoryStepProps>
> = ({ next }) => {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);
  const linkId = useLinkId();
  const [{ data: associates }] = useSuspendedQueries({
    queries: [dataAssociates(Access.VIEW_AND_EDIT).fetchAssociates(linkId)],
  });
  const { roaring } = useRecoilValue(routeState);

  const contract = useRecoilValue(contractState);
  const { companyName } = contract.contractData;
  const viewer = useViewerAsAssociate(associates, contract.contractViewer);
  const primary = (associates as Associate[]).find((associate) =>
    isPrimary(associate)
  );
  const primaryName = `${primary?.contact.firstName} ${primary?.contact.lastName}`;
  const viewerIsPrimary = viewer?.associateId === primary?.associateId;
  // const [view, setView] = useState<View>(
  //   (bank as ConfirmedStatus).source?.toString() ===
  //     BankAccountSource.BANKGIRO.toString()
  //     ? View.BANKGIRO
  //     : View.KLARNA
  // );

  const country = useCountry();
  const [confirmed, setConfirmed] = useState<Status>(Status.DEFAULT);

  // const onBankgiroSubmit = useCallback(() => {
  //   if (next) {
  //     next();
  //   } else {
  //     setConfirmed(Status.SUCCESS);
  //   }
  // }, [next]);

  // const onBankstatementSubmit = useCallback(() => {
  //   if (next) {
  //     next();
  //   } else {
  //     setConfirmed(Status.SUCCESS);
  //   }
  // }, [next]);

  const onNext = useCallback(() => {
    if (next) {
      next();
    } else {
      setConfirmed(Status.SUCCESS);
    }
  }, [next]);

  return (
    <StoryPage ref={ref}>
      <div className={styles.wrapper}>
        <div className="logo-wrapper">
          <Logo />
        </div>

        <div className="m-top-30">
          <LanguageSwitch />
        </div>

        <div className={styles.header}>
          <h2>
            <T
              id="Hi, {{name}}!"
              options={{
                name: `${viewer?.contact.firstName} ${viewer?.contact.lastName}`,
              }}
            />
          </h2>
        </div>

        <div className="m-top-30">
          {viewerIsPrimary ? (
            <p>
              <Trans t={t}>
                We're pleased that you've chosen Worldline as your payment
                service. However, we couldn't verify the payout account that was
                added. We will need you to choose another one.
              </Trans>
            </p>
          ) : (
            <>
              <p>
                <Trans t={t}>
                  <strong>{{ companyName } as TI}</strong> has been registered
                  for Worldline payment services by{" "}
                  <strong>{{ primaryName } as TI}</strong>.
                </Trans>
              </p>
              <div className="m-top-30">
                <NumberedItem index={1} color="#e5f2f4">
                  <Trans t={t}>
                    Perhaps {{ primaryName } as TI} has assigned you to add the
                    payout account
                  </Trans>
                </NumberedItem>
                <NumberedItem index={2} color="#e5f2f4">
                  <Trans t={t}>
                    Or we couldn't verify the previous payout account that was
                    added. We will need you to choose another one.
                  </Trans>
                </NumberedItem>
              </div>
            </>
          )}
        </div>

        {roaring ? (
          <div className={styles.statement}>
            <p>
              <T>Login to your bank in order to add the bank account</T>
            </p>
            <Roaring
              onNext={onNext}
              hideButton={confirmed === Status.SUCCESS}
            />
          </div>
        ) : null}

        {!roaring && country === Country.SWEDEN ? (
          <div className={styles.statement}>
            <BankgiroInner
              next={onNext}
              externalAccount={{ done: false }}
              disabled={confirmed === Status.SUCCESS}
              hideButton={confirmed === Status.SUCCESS}
            />
          </div>
        ) : null}

        {!roaring &&
        (country === Country.DENMARK || country === Country.FINLAND) ? (
          <div className={styles.statement}>
            <BankStatement
              next={onNext}
              externalAccount={{ done: false }}
              disabled={confirmed === Status.SUCCESS}
              hideButton={confirmed === Status.SUCCESS}
              onViewChange={() => {}}
            />
          </div>
        ) : null}

        <Dynamic name={confirmed}>
          {confirmed === Status.SUCCESS ? (
            <SuccessBox relative>
              <div className="text-large">
                <b>
                  <T>You're done!</T>
                </b>
                <div className="text-balance">
                  <p>
                    {viewerIsPrimary ? (
                      <T>
                        All is set. As soon as your application has been
                        reviewed you will receive a confirmation email.
                      </T>
                    ) : (
                      <T>All is set. Thank you for adding the bank account.</T>
                    )}
                  </p>
                  <p>
                    <T>It is safe to close this tab.</T>
                  </p>
                </div>
              </div>
            </SuccessBox>
          ) : null}
        </Dynamic>
      </div>
    </StoryPage>
  );
};
